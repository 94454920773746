<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      v-scroll="onScroll"
      :color="color"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <router-link
        v-if="!isHome"
        id="home-link"
        to="/"
      >
        <base-img
          v-if="!isHome"
          :src="require(`@/assets/logo.svg`)"
          contain
          max-width="300"
          width="100%"
        />
      </router-link>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      hasColor: false,
      drawer: null,
      items: [
        'Home',
        //  'About',
        //  'Marketing',
        //  'Gallery',
        //  'Pricing',
        //  'News',
        'Contact',
      ],
    }),

    computed: {
      isHome () {
        return this.$route.name === 'Home'
      },
      color () {
        if (this.isHome) {
          return this.hasColor ? 'white' : 'transparent'
        } else {
          return 'white'
        }
      },
    },

    methods: {
      onScroll () {
        this.hasColor = window.scrollY > 40
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none

  #home-link
    max-width: 300px
</style>
